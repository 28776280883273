<template>
    <div class="flex h-full flex-col justify-center text-center">
        <div class="mb-4 text-8xl font-bold text-gray-900">Oops!</div>
        <p class="line-height-3 mb-5 mt-0 text-xl font-medium text-gray-700">
            Something went wrong!
        </p>
        <nuxt-link
            :to="facilityHome"
            class="mt-4 text-gray-700"
        >
            <prime-button> Back to Home </prime-button>
        </nuxt-link>
    </div>
</template>

<script setup lang="ts">
import PrimeButton from 'primevue/button'
import { useActiveFacility } from '~/composables/use-active-facility'
import { computed } from 'vue'
import { useRouteLink } from '~/composables/routing'

const activeFacility = useActiveFacility()
const { facilityHomeLink } = useRouteLink()
const facilityHome = computed(() => facilityHomeLink(activeFacility.value.slug))
</script>
